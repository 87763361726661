










import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    Header: () => import('@/components/business/pc/header/InsHeader.vue'),
    Footer: () => import('@/components/business/pc/footer/InsFooter.vue'),
    Sidebar: () => import('@/components/business/pc/header/InsSidebar.vue'),
    Menu: () => import('@/components/business/pc/header/InsElMenu.vue')
  }
})
export default class pcIndex extends Vue {
  mounted () {
  }
}
